<template>
  <div>
    <div class="head-title-box">
      <el-row>
        <el-col :span="2" :offset="9" class="head-title-con">
          <i class="el-icon-arrow-left" @click="shangButton"></i>
        </el-col>
        <el-col :span="2" class="head-title-con">
          {{ greenName }}
        </el-col>
        <el-col :span="2" class="head-title-con">
          <i class="el-icon-arrow-right" @click="xiaButton"></i>
        </el-col>
        <el-col :span="2" :offset="6" class="head-title-con" @click.native="toVideo">
          视频监控
        </el-col>
      </el-row>
      <el-col style="margin-bottom: 40px">
        <el-col :span="5" :offset="3">选择时间单位
          <el-select v-model="value" placeholder="请选择" style="width: 120px;height: 40px">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="9">选择时间段
          <el-date-picker
              v-model="value2"
              type="daterange"
              align="right"
              unlink-panels
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getSelectTime"
              :picker-options="pickerOptions">
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-button style="width: 100px;height: 40px;background: #009400;border-radius: 8px;color: #FFFFFF;"
                     @click="getDataList">确定
          </el-button>
        </el-col>
      </el-col>
      <el-row style="height: 70px;margin-top: 50px">
        <el-col :span="3">
          <el-button :class="selectbox==='box1' ? 'selected-button-active' :'selected-button'"
                     @click="changeEchears('box1')">
            空气温度
          </el-button>
        </el-col>
        <el-col :span="3">
          <el-button :class="selectbox==='box2' ? 'selected-button-active' :'selected-button'"
                     @click="changeEchears('box2')">
            空气湿度
          </el-button>
        </el-col>
        <el-col :span="3">
          <el-button :class="selectbox==='box3' ? 'selected-button-active' :'selected-button'"
                     @click="changeEchears('box3')">
            土壤温度
          </el-button>
        </el-col>
        <el-col :span="3">
          <el-button :class="selectbox==='box4' ? 'selected-button-active' :'selected-button'"
                     @click="changeEchears('box4')">
            土壤湿度
          </el-button>
        </el-col>
        <el-col :span="3">
          <el-button :class="selectbox==='box5' ? 'selected-button-active' :'selected-button'"
                     @click="changeEchears('box5')">
            二氧化碳浓度
          </el-button>
        </el-col>
        <el-col :span="3">
          <el-button :class="selectbox==='box6' ? 'selected-button-active' :'selected-button'"
                     @click="changeEchears('box6')">
            光照度
          </el-button>
        </el-col>
        <el-col :span="3">
          <el-button :class="selectbox==='box7' ? 'selected-button-active' :'selected-button'"
                     @click="changeEchears('box7')">
            土壤PH值
          </el-button>
        </el-col>
        <el-col :span="3">
          <el-button :class="selectbox==='box8' ? 'selected-button-active' :'selected-button'"
                     @click="changeEchears('box8')">
            土壤导电率
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div style="text-align:center">
      <!--      <tem></tem>-->
      <div v-if="selectbox === 'box1'">
        <airTem :temper="temper"></airTem>
      </div>
      <div v-if="selectbox === 'box2'">
        <air-hun :humid="humid"></air-hun>
      </div>
      <div v-if="selectbox === 'box3'">
        <soilTem :landTemper="landTemper"></soilTem>
      </div>
      <div v-if="selectbox === 'box4'">
        <soil-hum :landHumid="landHumid"></soil-hum>
      </div>
      <div v-if="selectbox === 'box5'">
        <co2 :co2="co2"></co2>
      </div>
      <div v-if="selectbox === 'box6'">
        <light :light="light"></light>
      </div>
      <div v-if="selectbox === 'box7'">
        <land-p-h :landPH="landPH"></land-p-h>
      </div>
      <div v-if="selectbox === 'box8'">
        <land-elec :landElec="landElec"></land-elec>
      </div>
    </div>

    <div style="margin-top: 30px;width: 1200px;background: #FFFFFF">
      <div style="padding:20px 55px;width: 1090px;">

        <historyTab :tabList="tabList" :startTime="startTime" :endTime="endTime"></historyTab>
      </div>
    </div>
  </div>
</template>

<script>
import airHun from '@/components/canopyManageMent/hisEchars/airHun'
import soilHum from '@/components/canopyManageMent/hisEchars/soilHum'
import historyTab from '@/components/canopyManageMent/historyTab'
import co2 from "@/components/canopyManageMent/hisEchars/co2";
import airTem from "@/components/canopyManageMent/hisEchars/airTem";
import soilTem from "@/components/canopyManageMent/hisEchars/soilTem";
import landElec from "@/components/canopyManageMent/hisEchars/landElec";
import landPH from "@/components/canopyManageMent/hisEchars/landPH";
import light from "@/components/canopyManageMent/hisEchars/light";

export default {
  name: "CanopyManagement",
  components: {
    airHun,
    soilHum,
    co2,
    soilTem,
    airTem,
    landElec,
    landPH,
    light, historyTab
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      value1: '',
      value2: '', //时间区间
      startTime: '',
      endTime: '',
      selectbox: 'box1',
      is_abnormal: '', //是否存在异常
      // selectbox: 'box1',
      options: [{
        value: '1',
        label: '按小时'
      }, {
        value: '2',
        label: '按日'
      }],
      value: '',
      bId: '',//大棚id
      tabList: [],
      detailList: {},
      temper: [],
      landTemper: [],
      humid: [],
      landHumid: [],
      co2: [],
      light: [],
      landPH: [],
      landElec: [],
      greenHouseList: [],
      greenName: ''
    }
  },
  mounted() {
    this.bId = this.$route.query.id || localStorage.getItem('detailHisId')
    let thirtyDays = new Date(new Date().toLocaleDateString()).getTime() - 30 * 3600 * 24 * 1000; //最近30天
    let endDays = new Date(new Date().toLocaleDateString()).getTime()
    this.startTime = this.getTime(thirtyDays)
    this.endTime = this.getTime(endDays)
    this.value = 2
    this.getDataList()
    this.value = '日'
    this.value2 = [new Date(this.startTime),new Date(this.endTime)]

    this.$api.post('gHouse/getByBid', {}, res => {
      // console.log(res)
      this.greenHouseList = res.data
      let idx = res.data.findIndex((itm) => itm.id == this.bId)
      this.greenName = res.data[idx].name
    })
  },
  methods: {
    toVideo() {
      this.$router.push({
        name: 'videoList',
        query: {
          id: this.bId
        }
      })
    },
    getTime(nS) {
      var date = new Date(nS); //获取一个时间对象
      let y = date.getFullYear(); // 获取完整的年份(4位,1970)
      let m = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1; // 获取月份(0-11,0代表1月,用的时候记得加上1)
      let d = date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate(); // 获取日(1-31)
      let h = date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours(); // 获取小时数(0-23)
      let mm = date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes(); // 获取分钟数(0-59)
      let s = date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds(); // 获取秒数(0-59)
      return y + '-' + m + '-' + d + ' ' + h + ':' + mm + ':' + s;
    },
    selectChecked(data) {
      this.selectbox = data
    },
    changeEchears(data) {
      console.log(data, 'wowoowwo')
      this.selectbox = data
    },
    getSelectTime(data) {
      console.log(data, '获取值')
      this.startTime = data[0]
      this.endTime = data[1]
    },
    // getDataList(count,log){
    //   var time = new  Date().setMinutes(0);
    //   time = time-
    // }
    getDataList() {
      this.selectbox = 'box2'
      let params = {
        sTyp: this.value,
        sd: this.startTime,
        ed: this.endTime,
        gid: this.bId
      }
      this.$api.post('data/getData1', params, res => {
        console.log(
            res, 'fanhuzhi'
        )
        this.tabList = res.data

        if (res.code === 0) {
          for (let i = 0; i < res.data.length; i++) {
            this.temper.push(res.data[i].temper)
            this.landTemper.push(res.data[i].landTemper)
            this.humid.push(res.data[i].humid)
            this.landHumid.push(res.data[i].landHumid)
            this.co2.push(res.data[i].co2)
            this.light.push(res.data[i].light)
            this.landPH.push(res.data[i].landPH)
            this.landElec.push(res.data[i].landElec)
            this.selectbox = 'box1'
          }
        }
      })
    },
    xiaButton() {
      var idx1 = this.greenHouseList.findIndex((item) =>
          item.id == this.bId
      )
      console.log(this.greenHouseList.length, 'this.greenHouseList.length', idx1)
      if (!this.greenHouseList[idx1 + 1]) {
        console.log('最后一个')
      } else {
        console.log(idx1, '列表第几个greenHouseList', this.greenHouseList[idx1 + 1])
        localStorage.setItem("detailHisId", this.greenHouseList[idx1 + 1].id)
        this.$router.replace({
          name: "middlePage",
          query: {
            id: this.greenHouseList[idx1 + 1].id
          }
        })
      }
    },
    shangButton() {
      var idxa = this.greenHouseList.findIndex((item) =>
          item.id == this.bId
      )
      console.log(idxa, 'wwwwidxa')
      if (idxa <= 0) {
        console.log('第一个')
      } else {
        console.log(idxa, '列表第几个greenHouseList', this.greenHouseList)
        localStorage.setItem("detailHisId", this.greenHouseList[idxa - 1].id)
        this.$router.replace({
          name: "middlePage",
          query: {
            id: this.greenHouseList[idxa - 1].id
          }
        })
      }
    }

  }
}
</script>

<style scoped>
.head-title-box {
  height: 220px;
  background: #FFFFFF;
  margin-top: 20px;
  text-align: center;
}

select {

}

.head-title-con-name {
  margin-top: 9px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;

}

.head-title-con-type {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}


.head-title-con-left {
  height: 76px;
  margin-top: 10px;
}

.head-title-con {
  height: 76px;
  line-height: 76px;
  cursor: pointer;
}

.head-title-con-box {
  height: 200px;
  cursor: pointer;
  padding: 0 5px;
}

.head-title-con-box_actice {
  height: 200px;
  background: #CBE9CB;
  cursor: pointer;
  padding: 0 5px;
}

/*.head-title-con-img {*/
/*  height: 80px;*/
/*}*/


.head-title-con-type2 {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-top: 25px;

}

.head-title-con-data {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #0090FF;
  margin-top: 6px;
}

.selected-button-active {
  width: 140px;
  height: 60px;
  background: #009400;
  border-radius: 8px;
  color: #FFFFFF;
}

.selected-button {
  width: 140px;
  height: 60px;
  background: #CBE9CB;
  border-radius: 8px;
  color: #333333;
}

</style>
