<template>
  <div>
    <div class="tabLst-title">数据明细  <span class="tabLst-title-span" @click="exportTable">导出报表</span></div>
    <el-table
        :data="tabList"
        border
        class="table"
        style="width: 100%"
        :row-class-name="tableRowClassName">
      <el-table-column
          width="200"
          prop="createTime"
          label="时间"
      >
      </el-table-column>
      <el-table-column
          prop="temper"
          label="空气温度"
      >
      </el-table-column>
      <el-table-column
          prop="landTemper"
          label="土壤温度">
      </el-table-column>
      <el-table-column
          prop="humid"
          label="空气湿度">
      </el-table-column>
      <el-table-column
          prop="landHumid"
          label="土壤湿度">
      </el-table-column>
      <el-table-column
          prop="co2"
          label="二氧化碳浓度">
      </el-table-column>
      <el-table-column
          prop="light"
          label="光照度">
      </el-table-column>
      <el-table-column
          prop="landPH"
          label="土壤PH值">
      </el-table-column>
      <el-table-column
          prop="landElec"
          label="土壤导电率">
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
import FileSaver from "file-saver";
import XLSX from "xlsx"

export default {
  name: "historyTab",
  props: {
    tabList: {},
    startTime: {},
    endTime: {}
  },
  methods: {
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    exportTable() {
      var name = this.startTime + '至' + this.endTime + '历史数据'
      var wb = XLSX.utils.table_to_book(document.querySelector(".table"));
      /* get binary string as output */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });
      try {
        //  name+'.xlsx'表示导出的excel表格名字
        FileSaver.saveAs(
            new Blob([wbout], {type: "application/octet-stream"}),
            name + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;

    }
  }
}
</script>

<style scoped>
.tabLst-title {
  margin-top: 29px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  text-align: center;
  margin-bottom: 30px;

}

.tabLst-title-span {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #1A99F2;
  float: right;
  cursor: pointer;

}
</style>